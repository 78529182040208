import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal, Spin } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { CREATE_LIBRARY_DOCUMENT } from "../../../constants/mutations.js";
import { handleMutation, FILE_UPLOAD_ERROR_MSG } from "../../../utils/errorHandling.js";
import { DocumentUploadModal } from "../../common/DocumentUploadModal/index.js";
import { LIBRARY_FILE_EXTENTION_ARRAY } from "../../../utils/index.js";


export const UploadLibraryDocument = ({ 
    afterUpload, 
    disabled,
    currentFolderId, 
    baseFolderName, 
    contractTypeId 
}) => {
    const [uploadLibraryModal, setUploadLibraryModal] = useState(false);
    const [createLibraryFiles, setCreateLibraryFiles] = useState({});
    const [createLibraryDocument, createLibraryDocumentProgress] = useMutation(CREATE_LIBRARY_DOCUMENT);

    const resetState = () => {
        setUploadLibraryModal(false);
        setCreateLibraryFiles({});
    };

    return (
        <>
            <Modal
                title="Upload Library Document"
                destroyOnClose={true}
                maskClosable={false}
                closable={false}
                visible={uploadLibraryModal}
                okText="Save"
                onCancel={() => {
                    resetState();
                }}
                okButtonProps={{
                    disabled: Object.keys(createLibraryFiles).length === 0 || createLibraryDocumentProgress.loading || createLibraryFiles.size === 0
                }}
                cancelButtonProps={{
                    disabled: createLibraryDocumentProgress.loading
                }}
                onOk={async (e) => {
                    try {
                        e.preventDefault();
                        await handleMutation(
                            createLibraryDocument({
                                variables: {
                                    libraryDocumentInput: {
                                        documentName: createLibraryFiles.name,
                                        fileSize: (createLibraryFiles.size).toString(),
                                        libraryFolderId: currentFolderId,
                                        contractTypeId,
                                        baseFolderName,
                                        file: createLibraryFiles.originFileObj
                                    }
                                }
                            })
                        );
                        if (typeof afterUpload === "function") {
                            afterUpload();
                        }
                    } finally {
                        resetState();
                    }
                }}
            >
                <Spin size="large" spinning={createLibraryDocumentProgress.loading}>
                    <DocumentUploadModal
                        uploadFileMetaData={createLibraryFiles}
                        setUploadFileMetaData={setCreateLibraryFiles}
                        maxDocumentCount={1}
                        acceptingFileTypeList={LIBRARY_FILE_EXTENTION_ARRAY}
                    />
                </Spin>
                {Object.keys(createLibraryFiles).length != 0 && createLibraryFiles.size === 0 && (
                    <span style={{
                        marginTop: "10px",
                        color: "red",
                        fontStyle: "italic" 
                    }}>
                        <CloseCircleTwoTone twoToneColor="red" /> {FILE_UPLOAD_ERROR_MSG}
                    </span>
                )}
            </Modal>
            <Button
                type="primary"
                size="small"
                disabled={disabled}
                onClick={() => {
                    setUploadLibraryModal(true);
                }}
            >
                Upload File
            </Button>
        </>
    );
};