import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../Application/UserContext.js";
import { MODIFY_LIBRARY_DOCUMENT } from "../../constants/mutations.js";
import { S3_UPLOAD_STATUS } from "../../constants/index.js";
import { displayFileSizeUnit, formatTimeValue, isUndefinedOrNull } from "../../utils/index.js";
import { handleGraphQLError, handleMutation } from "../../utils/errorHandling.js";
import { Main } from "../common/Main/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { HeaderDetail } from "../common/HeaderDetail/index.js";
import { LabeledDetail } from "../common/LabeledDetail/index.js";
import { DocumentUploadStatusActiveTracker } from "../common/DocumentUploadStatusTracker/index.js";
import { LibraryDocumentActions } from "../common/LibraryDocumentActions/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { libraryDetailQuery } from "./query.js";


export const LibraryDetail = () => { 
    const { libraryDocumentId } = useParams(); 
    const [updatedDocument, setUpdatedDocument] = useState({});
    const [modifyLibraryDocument] = useMutation(MODIFY_LIBRARY_DOCUMENT);
    const {
        userPermDownloadLibDoc,
        userPermDownloadAssociatedLibDoc,
        userPermUploadnModifyLibDoc,
        userIsSubmitter
    } = useContext(UserContext);

    const { loading, error, data, refetch } = useQuery(libraryDetailQuery, {
        variables: { 
            id: libraryDocumentId, 
            includeS3Metadata: true   
        },
        fetchPolicy: "no-cache"
    }); 

    if (loading) {
        return <LoadingContent delay={500} />;
    }

    if (error) {
        return handleGraphQLError(error);
    } 

    if (isUndefinedOrNull(data?.libraryDocument)) {
        // either the ID in the URL is wrong, or the current user does not have permission to view this document 
        // in either case, return null early :: 
        return null;
    }

    const author = data.libraryDocument.author;
    const document = { ...data.libraryDocument, ...updatedDocument }; 

    const newBreadcrumb = {
        label: document.name,
        path: `/library/documents/${libraryDocumentId}`, 
        tooltip: `Document: ${document.name}`
    };

    const parentFolderIsArchived = document.parentFolderStatus === false;

    const userCanDownloadDocuments = userPermDownloadLibDoc || userPermDownloadAssociatedLibDoc;
    const documentIsArchived = !document.active; 

    // allow archive / de-archive if 
    //   1.) user has permission to archive, and
    //   2.) the parent folder is NOT archived, and
    //   3.) the document has been successfully uploaded (not quarantined or error status)
    //   4.) Report Template will have no Archive n Dearchive
    
    const isReportTemplate = data.libraryDocument.baseFolderId === data.libraryReportTemplate.id ? true : false;

    const shouldShowArchive = userPermUploadnModifyLibDoc && !parentFolderIsArchived && (document.S3UploadStatus === S3_UPLOAD_STATUS.uploaded) && !isReportTemplate;

    const libraryDocumentLink = `/library/${data.libraryDocument.contractTypeId}/${data.libraryDocument.baseFolderName}/${data.libraryDocument.baseFolderId}`;

    const details = (
        <>
            { documentIsArchived && 
                <p style={{ color: "red", fontWeight: "700" }}> Archived </p>
            }
            { userPermUploadnModifyLibDoc && !document.active && parentFolderIsArchived &&  
                <p> 
                    Note: This document cannot be de-archived because it is in an archived folder. 
                </p>
            }
            <HeaderDetail
                title="Author"
                to={`/users/${author.id}`}
                linkTitle={author.name}
                newBreadcrumbs={newBreadcrumb}
            /> 
            <HeaderDetail 
                title="Location"
                to={libraryDocumentLink}
                linkState={{
                    currentFolderName: document.parentFolderName,
                    currentFolderId: document.parentFolderId
                }}
                linkIcon={<FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />}
                linkTitle={document.parentFolderName}
            />
            <br />
            <LabeledDetail 
                title="File Size"
                value={displayFileSizeUnit(document.fileSize)}
            />
            <LabeledDetail
                title="Date Uploaded"
                value={formatTimeValue(document.dateCreated, true)}
            /> 
            <LabeledDetail 
                title="Last Modified"
                value={formatTimeValue(document.dateModified, true)}
            />
            <LabeledDetail 
                title="S3 Upload Status"
            >
                <DocumentUploadStatusActiveTracker
                    status={document.S3UploadStatus}
                    fileSize={document.fileSize}
                    fetchOptions={{
                        variables: { 
                            id: document.id, 
                            includeS3Metadata: false 
                        }, 
                        fetchPolicy: "no-cache"
                    }}
                    query={libraryDetailQuery}
                    documentTypeName={"libraryDocument"}
                />
            </LabeledDetail> 
            {  userCanDownloadDocuments && 
                <>
                    <LabeledDetail 
                        title="Actions"
                    >
                        <LibraryDocumentActions 
                            isSubmitter={userIsSubmitter}
                            record={document}
                            userCanUpload={userPermUploadnModifyLibDoc}
                            libraryFolderId={document.parentFolderId}
                            onReuploadFailure={() => {
                                refetch().finally(() => setUpdatedDocument({}));
                            }} 
                            reportUpdatedDocument={setUpdatedDocument} 
                        /> 
                    </LabeledDetail>
                </>
            }
        </>
    );

    const breadCrumbFolderSelected = data.libraryFolderPathway.map(({ name, id }) => {
        return (
            <PCDULink
                key={`breadcrumb-folder-${id}`}
                to={{
                    pathname: libraryDocumentLink
                }}
                state={{
                    currentFolderName: name,
                    currentFolderId: id
                }}
                tooltip={`Library Folder: ${name}`}
            >
                {name}
            </PCDULink>
        );
    });

    const breadcrumbs = [
        "Library Documents",
        data.libraryDocument.contractTypeName,
        <PCDULink
            key="library-breadcrumb"
            to={libraryDocumentLink}
            tooltip={`Library Folder: ${data.libraryDocument.baseFolderName}`}
        >
            {data.libraryDocument.baseFolderName} 
        </PCDULink>,
        ...breadCrumbFolderSelected,
        document.name 
    ];

    return  (
        <Main
            title={document.name}
            details={details}
            breadcrumbs={breadcrumbs} 
            showArchive={shouldShowArchive}
            archived={!document.active}
            confirmTitle={`Are you sure you want to ${documentIsArchived ? "de-archive" : "archive"} ${document.name}?`}
            confirmAction={async () => { 
                const options = { 
                    showSuccess: true, 
                    successMessage: `Document ${documentIsArchived ? "De-Archived" : "Archived"}`
                };

                await handleMutation(
                    modifyLibraryDocument({
                        variables: {
                            id: libraryDocumentId, 
                            active: !document.active
                        }
                    }), 
                    options
                ); 

                refetch().finally(() => setUpdatedDocument({}));
            }}
        />
    );
};
