import moment from "moment";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { Button, Form } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { DateableDetail } from "../common/DateableDetail/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { isoDate, hasAllOf } from "../../utils/functions.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { CREATE_CONTRACT_TYPE } from "../../constants/mutations.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { PCDULink } from "../common/PCDULink/index.js";


export const CreateContractType = () => {
    const navigate = useNavigate();
    const [modified, setModified] = useState({});
    const [showNewLoading, setShowNewLoading] = useState(false);
    const [createContractType] = useMutation(CREATE_CONTRACT_TYPE);

    const { userPermCreateContractTypes } = useContext(UserContext);
    if (!userPermCreateContractTypes) {
        return <NoPermission />;
    }

    const createButtonEnabled = hasAllOf(modified, "name", "specifier", "startDate", "endDate");

    const details = (
        <Form
            layout="vertical"
            autoComplete="off"
        >
            <div id="details" style={{ marginTop: "10px" }}>
                <EditableDetail
                    title={"Name"}
                    value={modified.name}
                    strict={true}
                    onValueUpdated={(e) => {
                        setModified({
                            ...modified,
                            name: e.target.value
                        });
                    }}
                />
                <EditableDetail
                    title={"ID"}
                    value={modified.specifier}
                    strict={true}
                    onValueUpdated={(e) => {
                        setModified({
                            ...modified,
                            specifier: e.target.value
                        });
                    }}
                />
                <div>
                    <DateableDetail
                        title="Start Date"
                        key="startDate"
                        value={modified.startDate ? moment(modified.startDate) : ""}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                startDate: isoDate(value)
                            });
                        }}
                    />
                </div>
                <div style={{ marginTop: "12px" }}>
                    <DateableDetail
                        title="End Date"
                        key="endDate"
                        value={modified.endDate ? moment(modified.endDate) : ""}
                        readOnly={!modified.startDate ? true : false}
                        disabledDate={(selectedDate) => {
                            const currentDate = moment().endOf("day");
                            const startDate = modified.startDate ? moment(modified.startDate).endOf("day") : "";
                            if (startDate && startDate > currentDate) {
                                return selectedDate && selectedDate < startDate;
                            } else {
                                return selectedDate && selectedDate < currentDate;
                            }
                        }}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                endDate: isoDate(value)
                            });
                        }}
                    />
                </div>
                {createButtonEnabled
                    ? <Button
                        style={{ marginTop: "10px" }}
                        type="primary"
                        disabled={modified.createNewAmendment}
                        loading={showNewLoading}
                        onClick={async () => {
                            setShowNewLoading(true);

                            const id = await handleMutation(
                                createContractType({
                                    variables: {
                                        contractType: {
                                            ...modified
                                        }
                                    }
                                })
                            );

                            if (id) {
                                navigate(`/contract-types/${id}`);
                            } else {
                                setModified({});
                                setShowNewLoading(false);
                            }
                        }}
                    >
                        Create Contract Type
                    </Button>
                    : null
                }
            </div>
        </Form>
    );

    return (
        <Main
            title={"Create a Contract Type"}
            details={details}
            breadcrumbs={[
                <PCDULink to="/contract-types" tooltip={"Contract Types"}>Contract Types</PCDULink>
            ]}
        />
    );
};