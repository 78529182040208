import { gql } from "@apollo/client";


export const libraryDetailQuery = gql`
    query LibraryDetailQuery ($id: ID!, $includeS3Metadata: Boolean) {
        libraryDocument(
            id: $id
            includeS3Metadata: $includeS3Metadata 
        ) {
            id
            name
            fileSize
            libraryFolderId
            contractTypeId
            baseFolderId
            dateModified
            dateCreated 
            active
            contractTypeName
            parentFolderName 
            parentFolderId
            parentFolderStatus
            baseFolderName
            S3UploadStatus 
            failedS3Fetch 
            S3Metadata 
            author {
                id 
                name 
            }
            reportTemplate {
                name 
                description 
                versions {
                    id 
                    fileSize 
                    name 
                    dateCreated 
                    dateModified 
                    S3UploadStatus
                    specifier 
                    startDate 
                    author {
                        id 
                        name 
                    }
                }
            }
        }
        libraryFolderPathway (
            libraryDocumentId: $id 
        ) {
            name 
            id 
            
        } 
        libraryReportTemplate {
            name
            id
        }
    }
`;
